*{ margin: 0; padding: 0; font-family: "微软雅黑"}
body, ul, li, h1, h2, h3, h4, h5, h6, p, form, dl, dt, dd { margin: 0px; padding: 0px; font-size: 14px; font-weight: normal;}
img { border-style: none;}
li{ list-style: none; float: left}
a{ text-decoration: none}
body {	
    background-image: url(https://cdn.jsdelivr.net/gh/appexplore/cdn/jianavi@v2.3/img/01.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

/*百度搜索*/
.baidu{
	float: left;
	margin: 8px 0;
	margin-left: 100px;
	}
.baidu form{
	position: relative
}
#Select{
	float: left;
	border: 1px solid #ccc	
	}

.Select-box{
	text-align: center;
	float: left;
	position: relative;
	}
.Select-box ul{
	height: 40px;
	position: absolute;
	left: -1px;
	top: 0px;
	z-index: 9999;
	background: #FFF;
	border: 1px solid #ccc;
	border-top: none;
	overflow: hidden
	}
.Select-box li{
	width: 60px;
	line-height: 40px;
	font-size: 14px;
	color: #484848;
	border: 0;
	cursor: pointer;
	}
.Select-box li:hover{
	background: #3385ff;
	color: #FFF;
	}
.Select-box .this_s{
	color: #317ef3;
	}
.Select-box .this_s:hover{
	background: #FFF;
	color: #317ef3;
	}
	
.qingkong{
	position: absolute;
	right: 95px;
	top: 12px;
	width: 18px;
	height: 18px;
	background: rgba(0,0,0,0.1);
	border-radius: 18px;
	line-height: 16px;
	color: #666666;
	cursor: pointer;
	text-align: center;
	font-size: 14px;
	display: none;
}
.qingkong:hover{
	background: rgba(0,0,0,0.2);
}
.qingkong:active{
	background: rgba(0,0,0,0.3);
}
.content{
	width: 1100px;	
    position: absolute; 
    left: 50%;
    transform: translateX(-50%);
    top:25%;
}
.qingkong{
	right: 115px;
	top: 15px;
}
/*内容区域*/
.body{
	width: 100%;
	height: auto;
	z-index: 1;
}
.bg-2{
	padding-top: 60px;
}
.content{
	width: 1100px;
	overflow: hidden;
	margin: 30px auto;
}
	
	
.nav-list{
	width: 1100px;
	}

/*优秀网站推荐*/

/*小分栏*/
.mix-content{
	width: 1120px;
	margin: 0 auto;
	overflow: hidden;
}

.left-tips{
	font-size: 12px;
	color: #959595;
	float: left;
}
.right-tips{
	font-size: 12px;
	color: #959595;
	float: right;
}

.tit-style2{
	margin-top: 35px;
	padding: 0 50px;
	padding-top: 30px;
	line-height: 40px;
	border-top: 2px solid #FFF;
	border-bottom: 2px solid #FFF;
	display: inline-block;

}

.min-tit{
	font-size: 15px;
	color: #959595;
}

/*-----------------------------简洁版样式定义- 开始---------------------------------------------------*/

.baidu-2{
	width: 100%;
	height: 110px;
	margin: 0 auto;
	background: none;
	padding-top: 90px;
	z-index: 1;
	}
.baidu-2 form{
	width: 520px;
	margin: 0 auto;
}
.baidu-2 input{
	padding: 13px 8px;
	opacity: 0.9;
	font-size: 15px;
	}
#Select-2{
	float: left;
	}

.Select-box-2{
	text-align: center;
	float: left;
	position: relative;
	}
.Select-box-2 ul{
	height: 48px;
	position: absolute;
	left: 0px;
	top: 0px;
	z-index: 9999;
	background: rgba(255,255,255,0.9);
	border: none;
	overflow: hidden
	}
.Select-box-2 li{
	width: 60px;
	line-height: 46px;
	font-size: 15px;
	color: #484848;
	border: 0;
	cursor: pointer;
	width: 80px;
	}
.Select-box-2 li:hover{
	background: #3385ff;
	color: #FFF;
	}
.Select-box-2 .this_s{
	color: #317ef3;
	width: 80px;
	}
.Select-box-2 .this_s:hover{
	background: none;
	color: #317ef3;
	}

	#kw-2 {
    width: 413px;
    outline: 0;
    border: 1px solid #ccc;
    background: rgba(255,255,255,0.2);
    color: #000000;
    padding-left: 8px;
    font-weight: bold;
    height: 48px;
    box-sizing: border-box;
}
#su-2{
	width: 90px;
	background: blue;
	
	border-top: #3385ff 1px solid;
	border-bottom: 1px solid #2d78f4;
	color: #FFF;
	cursor: pointer;
	/*去轮廓阴影*/
	outline: none;
	height: 48px;
	border: none;
	}	
#su-2:hover{
	background: blue;
	border-bottom: 1px solid blue;
	}
#su-2:active{
	background: blue;
	box-shadow: inset 1px 1px 3px blue;
	-webkit-box-shadow: inset 1px 1px 3px blue;
	}


.jianjie{
	width: 1170px;
	z-index: 1;
}
.jj-list{
	width: 350px;
	margin-right: 25px;
	float: left;
	margin-bottom: 20px;
	z-index: 1;
}
.jj-list-tit{
	font-size: 16px;
	line-height: 25px;
	color: #ffffff;
	padding-left: 4px;
	z-index: 1;
}
.jj-list-con{
	overflow: hidden;
	z-index: 1;
}
.jj-list-con li{
	width: 110px;
	margin: 3px 3px;
}
.link-3{
	display: block;
	background: rgba(0,0,0,.35);
	color: #FFF;
	font-size: 13px;
	text-align: center;
	line-height: 35px;
	padding: 4px 0;
	border-radius: 2px;
	transition: all 0.2s;
	z-index: 1;
}
.link-3:hover{
	background: rgba(0,0,0,.45);
	font-size: 15px;
	font-weight: bold
}

.footer {  
	color: #ffffff;
    font-size: 12px;
    text-align: center;
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
}

.footer1{
	display: none;
}



/*-----------------------------简洁版样式定义- 结束---------------------------------------------------*/


div#baidu {
    width: 80px;
    /* height: 50px; */
    height: 1.2rem;
    /* background-color: #fff; */
    box-sizing: border-box;
    text-align: center;
}

.baidu-2 form {
    max-width: 520px;
    width: 80%;
    display: flex;
    justify-content: space-between;
    height: 1.2rem;
    padding: 0;
    z-index: 2;
}

.in5 {
    width: calc(100% - 160px);
    position: relative;
    box-sizing: border-box;
}

div#qingkong {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 14px;
    color: #e6e6e6;
}

input#su-2 {
    width: 79px;
    box-sizing: border-box;
}

#kw-2 {
    width: 100%;
    border-left: none;
    border-right: none;
}

@media screen and (max-width: 768px) {
	ul.keylist {
	    width: calc(80% - 0px) !important;
	}
	/*整个表单宽度*/
	.baidu-2 form{
		
	}
	div.in5 {
	    width: calc(100% - 80px);
	}
	input#su-2{
		position: absolute;
    	right: 0;
	}
}
.keylist {
    max-width: 360px;
    width: calc(80% - 160px);
    box-sizing: border-box;
    z-index: 52;
    margin: auto;
}

ul.keylist {
    outline: 0;
    background: #fff;
    color: #000000;
    font-weight: bold;
    position: relative;
}
ul.keylist li {
    float: none;
    padding: 3px 8px;
}
.keylist li:hover{
	background-color:#f1f1f1;
}
.keylist li.active{
	background-color:#f1f1f1;
}
body, ul, li, h1, h2, h3, h4, h5, h6, p, form, dl, dt, dd {
    z-index: 1;
}

/*zdy*/
.zdy_button img {
    width: 36px;
    float: right;
    margin: 10px 30px;
    cursor: pointer;
}
.zdy{
    width: 100%;
    margin: auto;
    height: 100%;
    position: fixed;
    left: 0px;
    top: 0px;
    background: rgb(0,0,0,0.6);
    overflow: auto;
    display: none;
    z-index: 6;
}
.zdy_content{
    background: white;
    width: 600px;
    
    margin: auto;
    margin-top: 50px;
    border-radius: 5px;
    padding: 20px 0px;
    font-size: 23px;
}
span.zdy_edit {
	/*url(img/edit.ico)*/
    background: url(../img/edit.ico);
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
}
span.zdy_close {
	float: right;
    background: url(../img/close.jpg);
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
}
.zdy_close:hover,.zdy_b:hover{
	opacity: 0.7;
}
.zdy_top {
    border-bottom: 1px solid #d2d2d2;
    padding: 0 20px 20px;
    margin-bottom: 10px;
}
.zdy_b {
    width: 100px;
    height: 45px;
    border: 0px;
    border-radius: 5px;
    color: white;
    text-align: center;
    line-height: 44px;
    display: inline-block;
    cursor: pointer;
}
.zdy_false {
    background-color: #d2d2d2;
    margin-left: 155px;
    margin-right: 90px;
}
.zdy_true{
	background-color: #0271CD;
}
.zdy_foot {
    padding: 20px 0 0;
    border-top: 1px solid #d2d2d2;
    margin-top: 20px;
}
.z_m_i {
    padding: 10px 20px 0;
    box-sizing: border-box;
    position: relative;
}
span.z_i_img_1 {
    background: url(../img/a.jpg) no-repeat;
    display: inline-block;
    width: 20px;
    background-size: 18px;
    height: 20px;
    position: absolute;
    top: 24px;
    left: 25px;
}
span.z_i_img_2 {
    background: url(../img/link.jpg) no-repeat;
    display: inline-block;
    width: 20px;
    background-size: 18px;
    height: 20px;
    position: absolute;
    top: 24px;
    left: 25px;
}
input.zdy_input {
    background: white;
    width: 100%;
    margin: auto;
    border-radius: 5px;
    padding: 8px 30px;
    box-sizing: border-box;
}
.jj-list-con li{
	position: relative;
	height: 42px;
}
.i_r_remove {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 42px;
    background-color: red;
    color: #fff;
    display: none; 
    cursor: pointer;
    background: url(../img/close.jpg) red;
    background-size: 20px 20px;
}
.i_r_remove:hover{
	background-color: #fc0b0b;
}
.jj-list-con li:hover .i_r_remove{
	display: block;
}

.i_r_edit {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 42px;
    color: #fff;
    display: none; 
    cursor: pointer;
    background: url(../img/edit.ico) #d2d2d2;
    background-size: 20px 20px;
}

.zdy_button {
    position: absolute;
    right: 0;
}
